// @ts-check
import React, { useState, useEffect} from "react";
import { Link, Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom"; 

export function urlToRelative(abs) {
    if (abs.startsWith("/")){
        return abs;
    }
    const url = new URL(abs);
    const rel = url.toString().substring(url.origin.length);
    return rel;
  };

export function Login({onLoginStatusChanged}){
    const navigate = useNavigate();

    let [searchParams, setSearchParams] = useSearchParams();
    let next = "";
    for (const [key, value] of searchParams) {
        if (key === "next"){
            next = urlToRelative(decodeURIComponent(value));
        }
    }

    const [loginSuccess, setLoginSuccess] = useState(false);

    useEffect(() => {
        if (loginSuccess){
            console.log("Navigating from effect");
            navigate(next === "" ? "/account" : next, {replace: true});
        }
    }, [loginSuccess]);

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const [message, setMessage] = useState(null);

    const handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
          ...formData,
          [name]: value
        });
      };

    const handleSubmit = (e) => {
        setMessage(null);
        e.preventDefault();
        if (formData.email !== "" && formData.password !== ""){
            fetch(__API_URL__ + '/check-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: formData.email,
                    password: formData.password
                })
            }).then(response => response.json())
            .then(data => {
                if (data.success){
                    console.log("Login was successful");
                    onLoginStatusChanged();
                    // We navigate in an effect, because the login status
                // change re-renders the component and interferes with the navigation. 
                    setLoginSuccess(true);
                }
                else{
                    setMessage(data.error);
                }
            });
        
        }
    }

    let message_block = message !== null ? <div className="formerror">{message}</div> : null;

    return <form method="post" action="#" onSubmit={handleSubmit}>
        <div className="form-group">
            <label htmlFor="username">E-Mail Adresse
                <input type="email" tabIndex={1} value={formData.email} onChange={handleInputChange}
                 autoComplete="email" className="form-control" id="username" 
                name="email" required autoFocus />
                <span className="smallNote">Noch kein Konto? <Link tabIndex={4} to="/register">Jetzt registrieren</Link></span>
            </label>
        </div>
        <div className="form-group">
            <label htmlFor="password">Passwort 
                <input type="password" value={formData.password} onChange={handleInputChange}
                 tabIndex={2}  autoComplete="current-password" required 
                className="form-control" id="password" name="password" />
            </label>
        </div>
        {message_block}

        <div className="form-group">
            <input type="submit" className="btn-primary btn" tabIndex={3} value="Anmelden" />
        </div>
        <div className="forgot-link">
            <Link tabIndex={5} to="/forgot-password">Passwort vergessen?</Link>
        </div>
    </form>
}