// @ts-check
import React, { useState, useEffect} from "react";
import { Outlet, NavLink, Routes, Route, useOutletContext, useParams, useLoaderData, json, redirect } from "react-router-dom";
import { MainWrapper } from "./MainWrapper.js";

export async function accountLoader({request}){
  const login_url = "/login?next=" + encodeURIComponent(request.url);
  let url = "/webapi/userinfo";
  let res = await fetch(__API_URL__ + url,{
      signal: request.signal
  });
  if (res.status === 401){
      return redirect(login_url);
  }
  return json(await res.json());
}

export function useTitle(title){
  const { setTitle } = useOutletContext();

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);
};

export function AccountRoot(){

    const user = useLoaderData();

    let roles = user.roles ?? [];
    if (!Array.isArray(roles))
    {
        roles = Object.values(roles);
    }
    const isAdmin = roles.includes("ROLE_ADMIN");

    const [title, setTitle] = useState("");

    const { userId } = useParams();

    function linkStyle({isActive}){
      return isActive ? "small button" :  "small button alt";
    }

    function makeTarget(url){
        if (userId !== null && userId !== undefined){
            return url + "/" + userId;
        }
        return url;
    }

    return (
    <MainWrapper>
        <h2>{title}</h2>
        <ul className="nav telani-nav-tabs" role="tablist">
            <li role="presentation">
            <NavLink className={linkStyle} end to={makeTarget("/account")} role="tab">Informationen</NavLink>
            </li>
            <li role="presentation">
            <NavLink className={linkStyle} to={makeTarget("/account/changePw")} role="tab">Passwort ändern</NavLink>
            </li>
            <li role="presentation">
            <NavLink className={linkStyle} to={makeTarget("/account/manage_subscription")} role="tab">Jahres-Abo</NavLink>
            </li>
            {isAdmin &&
            <li role="presentation">
            <NavLink className={linkStyle} to={makeTarget("/account/advanced-details")} role="tab">Erweitert</NavLink>
            </li>}
        </ul>
        <Outlet context={{ title, setTitle }}/>
    </MainWrapper>
    );
}
