// @ts-check

import React from "react";
import { Await, defer, json, redirect, useLoaderData } from "react-router-dom";
import { HeaderedSection } from "./HeaderedSection.js";
import { DateFormat } from "./DateFormat.js";
import { SentNotificationsList } from "./SentNotificationsList.js";

export async function advancedUserDetailsLoader({params, request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    let { userId } = params;
    if (userId === undefined){
        const res = await fetch(__API_URL__ + `/webapi/userinfo`,{
            signal: request.signal
        });
        if (res.status === 401){
            return redirect(login_url);
        } 
        const user = await res.json();
        userId = user.id;
    }
    const dataPromise = fetch(__API_URL__ + "/webapi/issued-licenses/" + userId, {
        signal: request.signal
    }).then(res => res.json());
    return defer({userId: userId, data: dataPromise});
}

export function AdvancedUserDetails(){
    const {userId, data: dataPromise} = useLoaderData();

    return <>
            <HeaderedSection title="Benachrichtigungen">
                <SentNotificationsList userId={userId} />
            </HeaderedSection>
            <HeaderedSection title="Ausgestellte Lizenzen">
            <React.Suspense fallback={<p>Laden...</p>}>
                <Await
                    resolve={dataPromise}
                    errorElement={
                        <p>Laden fehlgeschlagen.</p>
                    }>
                        {(data) => (
                        (data.licenses === undefined || data.licenses === null || data.licenses.length === 0) ?
                            <p>Noch keine Lizenzen ausgestellt.</p>
                            :
                <ul>
                {data.licenses.map((license) => {
                return <li key={license.id}><DateFormat value={new Date(license.date)} /></li>
            })}</ul>)}
                    </Await>
                </React.Suspense>
            </HeaderedSection>

    </>
}