// @ts-check
import React, { useState, useEffect} from "react";

export function RegisterAdvantages(){
    return (
    <section>    
        <h4 className="wp-block-heading">Holen Sie sich uneingeschränkten Zugriff auf <strong>telani</strong> für 14 Tage!</h4>
        <ul className="checklist wp-block-list">
            <li>keine Kreditkarte notwendig</li>
            <li>Test endet nach 14 Tagen ohne Kündigung</li>
            <li>keine Beschränkungen</li>
            <li>alle Module</li>
        </ul>
        <div className="wp-block-group option-feature-description has-global-padding is-layout-constrained wp-block-group-is-layout-constrained">
            <h4 className="wp-block-heading">Was ist enthalten?</h4>
            <ul className="checklist wp-block-list">
                <li>intuitive Anwendung um Brandfallsteuermatrizen zu erstellen</li>
                <li>professioneller E-Mail Support</li>
                <li>alle Updates</li>
                <li>für alle Ihre Projekte</li>
                <li>keine Projektdaten in der Cloud</li>
            </ul>
        </div>
    </section>
    );
}
