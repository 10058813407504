// @ts-check
import React from "react";
import { Form, json, redirect, useActionData, useLoaderData } from "react-router-dom";

export async function adminRegisterAction({ request, params }){
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    const formData = await request.formData();

    const newPassword1 = formData.get("admin_register[plainPassword][first]");
    const newPassword2 = formData.get("admin_register[plainPassword][second]");
    if (newPassword1.length < 8){
        return ({success: false, error: "Ihr neues Passwort muss mindestens 8 Zeichen lang sein.", target: "password"});
    }
    if (newPassword1 !== newPassword2){
        return ({success: false, error: "Die Passwörter stimmen nicht überein.", target: "password"});
    }
    const res = await fetch(__API_URL__ + "/webapi/admin-register", {method: "POST", body: formData});
    const data = await res.json();
    if (data.success){
        console.log("Successfully registered");
        return redirect("/account/" + data.user_id);
    }
    else {
        console.log("Errors occurred");
        return data;
    }
}

export async function adminAddUserLoader({request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    const res = await fetch(__API_URL__ + '/webapi/admin-register-token', {
        signal: request.signal
    });
    if (res.status === 401){
        return redirect(login_url);
    }
    const data = await res.json();
    return json({token: data["token"]});
}

export function AdminAddUser(){

    let actionData = useActionData();

    let message = null;
    let password_message = null;

    if (actionData !== undefined && actionData !== null && !actionData.success){
        if (actionData.target === "password"){
            password_message = {isError: true, message: actionData.error};
            message = {isError: true, message: actionData.error};
        }
        else{
            message = {isError: true, message: actionData.error};
        }
        console.log("action data", actionData.success, actionData.error)
    }

    let message_block = message !== null ? <div className={`alert ${message.isError ? "alert-danger" : "alert-success"}`}>{message.message}</div> : null;
    let password_message_block = password_message !== null ? <div className={`alert ${password_message.isError ? "alert-danger" : "alert-success"}`}>{password_message.message}</div> : null;

    const {token} = useLoaderData();

    return (
        <Form method="POST">
            <label>
            E-Mail Adresse
            <input type="email"name="admin_register[email]" />
            </label>
            <p>E-Mail wird nicht bestätigt, bitte nur korrekte E-Mails anlegen.</p>
            {password_message_block}
            <label>
            Passwort (min 8 Zeichen)
            <input type="password" name="admin_register[plainPassword][first]" />
            </label>
            <label>
            Passwort wiederholen
            <input type="password" name="admin_register[plainPassword][second]" />
            </label>
            <p>Passwort wird Benutzer <i>nicht</i> automatisch mitgeteilt, auch AGB und Datenschutz müssen extern betrachtet werden.</p>
            <input type="hidden" name="admin_register[_token]" value={token} />
            {message_block}
            <input type="submit" value="Hinzufügen" />
        </Form>
    )
}