// @ts-check
import React from "react";
import { Menu } from "./Menu.js";
import { Outlet } from "react-router-dom";

export function IndexPage ({menuRenderPasses}) {
    return (<>
    <header id="header">
        <h1><a href={__API_URL__} className="icon solid">
            <img className="textLogo" width="150"
             height="35" alt="telani"
            src="/img/logo-text.svg" />
        </a></h1>
        <nav id="nav">
            <ul id="react-menu">
                <Menu renderPasses={menuRenderPasses} />
            </ul>
        </nav>
    </header>
    <div id="page-wrapper">  
        <Outlet />
    </div>
    <footer id="footer">
        <ul className="icons">
            <li><a href="https://facebook.com/telaniapp" rel="noopener" title="Facebook" className="icon">
                <i className="fa-brands fa-facebook-f"></i>
            <span className="label">Facebook</span></a></li>

            <li><a href="https://www.linkedin.com/company/telani-app/" rel="noopener" title="LinkedIn" className="icon">
                <i className="fa-brands fa-linkedin-in"></i>
            <span className="label">LinkedIn</span></a></li>
        </ul>
        <div className="copyright">
            <p>&copy; 2015 - {new Date().getFullYear()} <a href="https://gb-t.de" className="impressum">gb&amp;t Geb&auml;udebestand &amp; Technik GmbH</a></p>
            <ul className="legal-links">
                <li><a href="/impressum" className="impressum">Impressum</a></li>
                <li><a href="/bedingungen" className="impressum">AGB</a></li>
                <li><a href="/datenschutz" className="impressum">Datenschutzerklärung</a></li>
            </ul>
        </div>
    </footer></>);
}