// @ts-check
import React, { useState, useEffect, useRef} from "react";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { redirect, useLoaderData } from "react-router-dom";


export async function forgotPasswordLoader({request}){
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    const response = await fetch(__API_URL__ + '/webapi/forgotten-password-token',{
        signal: request.signal
    });
    if (response.status === 401){
        return redirect(login_url);
    }
    const data = await response.json();
    return {token: data["token"], siteKey: data["captcha-site-key"]};
}

/**
 * @typedef Message
 * @property {boolean} isError
 * @property {string?} message
 */

export function ForgotPassword(){
    const captchaRef = useRef(null);

    const {token, siteKey} = useLoaderData();

    const [email, setEmail] = useState("");
    const [captchaResponse, setCaptchaResponse] = useState("");
    const [message, setMessage] = useState(/** @type {Message?} */(null));

    function handleSubmit(ev){
        ev.preventDefault();
        setMessage(null);
        if (captchaResponse !== "" && email !== ""){
            fetch(__API_URL__ + "/webapi/forgot-password", {
                method: "post",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: email,
                    hCaptchaResponse: captchaResponse,
                    _token: token
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success){
                        if (captchaRef.current){
                            captchaRef.current.resetCaptcha();
                        }
                        setEmail("");
                        setMessage({isError: false, message: "Wir haben Ihnen eine E-Mail gesendet mit Anweisungen um ein neues Passwort zu vergeben."});
                    }
                    else{
                        setMessage({isError: true, message: data.error});
                    }
                });
        }
        else{
            setMessage({isError: true, message: "Bitte E-Mail und Sicherheitsabfrage ausfüllen."});
        }
    }

    function handleVerificationSuccess(token, ekey){
        setCaptchaResponse(token);
    }

    function handleEmailChange(ev){
        setEmail(ev.target.value);
    }

    const onExpire = () => {
        console.log("hCaptcha Token Expired");
      };
    
    const onError = (err) => {
        console.log(`hCaptcha Error: ${err}`);
      };    

    let message_block = message !== null ? <div className={`alert ${message.isError ? "alert-danger" : "alert-success"}`}>{message.message}</div> : null;

    return <>
        <p>Bitte geben Sie Ihre E-Mail Adresse ein, dann senden wir Ihnen eine E-Mail 
        mit Anweisungen zum Wiederherstellen Ihres Passworts zu.</p>

        <form name="forgotten_password" onSubmit={handleSubmit} method="post" action="#">
            <div>
                <label htmlFor="forgotten_password_email" className="required">
                    E-Mail
                    <input autoComplete="email" type="email" value={email} onChange={handleEmailChange} id="forgotten_password_email" name="email" required className="form-control" />
                </label>
            </div>
            
            <div>    
                <span className="fake-label">Sind Sie ein Roboter?</span>
                <HCaptcha sitekey={siteKey} 
                    ref={captchaRef}
                    onError={onError}
                    onExpire={onExpire}
                    onVerify={(token,ekey) => handleVerificationSuccess(token, ekey)} />        
            </div>
            { message_block }
            <div className="clearfix form-group">
                <input type="submit" value="Senden" className="btn btn-primary pull-right" />
            </div>
        </form>
        </>
}