// @ts-check
import React from "react";
import { json, useLoaderData } from "react-router-dom";
import { useTitle } from "./AdminRoot.js";
import { GoToLogin } from "./react-entry.js";

export async function emailDebugLoader({request}){
    const res = await fetch(__API_URL__ + "/webapi/email-debug-output",{
        signal: request.signal
    });
    if (res.status === 401){
        GoToLogin();
    }
    return json({content: await res.text()});
}

export function EmailDebugOutput(){
    useTitle("E-Mail Templates");

    const {content} = useLoaderData();

    const markup = { __html: content };
    return <>
        <p>Das Design der E-Mails ist ähnlich, aber nicht identisch zu diesem Design:</p>
        <div dangerouslySetInnerHTML={markup}></div>
    </>
}