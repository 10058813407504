// @ts-check
import React, { useEffect, useState } from "react"
import { GoToLogin } from "./react-entry.js";
import { useNavigate, useParams } from "react-router-dom";

export function TermsConsent() {
    
    const navigate = useNavigate();

    const [token, setToken] = useState(false);
    const [message, setMessage] = useState(/** @type {string?} */(null));

    const [formData, setFormData] = useState({
        terms: false,
        privacy: false
    });

    const { next } = useParams();

    const next_render = (next !== undefined && next !== null && next !== "") ? new URL(decodeURIComponent(next)) : null;

    useEffect(() => {
        updateToken();
    }, []);

    function updateToken(){
        fetch(__API_URL__ + '/webapi/termsConsentToken')
            .then(response => response.status === 401 ? GoToLogin() : response.json())
            .then(data => {
                setToken(data["token"]);
        });
    }

    function handleChange(e){
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData({
          ...formData,
          [name]: value
        });
    }

    function handleFormSubmit(event){
        event.preventDefault();
        if (formData.privacy && formData.terms){
            setMessage(null);
            fetch(__API_URL__ + `/webapi/terms-consent?token=${token}`, {
                method: "POST"
            })
            .then(response => response.status === 401 ? GoToLogin() : response.json())
            .then(data => {
                if (data.success){
                    if (next_render !== null){
                        navigate(next_render.pathname);    
                    }
                    else {
                        navigate("/account", {replace: true});
                    }
                }
                else if (data.wrong_token){
                    updateToken();
                    setMessage(data.message);
                }
                else{
                    setMessage("Bitte versuchen Sie es erneut.");
                }
            })
        }
        else{
            setMessage("Bitte akzeptieren Sie unsere Datenschutzerklärung und unsere AGB.");
        }
    }

    let message_block = message !== null ? <div className="alert alert-danger">{message}</div> : null;

    return <>
        <p>
            Seit Ihrem letzten Login haben sich unsere <a href='/bedingungen'>Allgemeinen Geschäftsbedingungen</a> und unsere <a href='/datenschutz'>Datenschutzerklärung</a> geändert. Bitte nehmen Sie sich einen Moment Zeit und überprüfen diese.
        </p>
        <p>
            Bei Fragen können Sie sich per E-Mail an uns wenden: <a href="mailto:support@telani.net">support@telani.net</a>
        </p>
        <form onSubmit={handleFormSubmit} method="post">
            <div className="form-group">
                <div>
                    <div className="checkbox">
                        <label className="required" htmlFor="consent_update_form_privacy_policy_accepted"> 
                            <input type="checkbox"
                                id="consent_update_form_privacy_policy_accepted"
                                checked={formData.privacy} 
                                onChange={handleChange}
                                name="privacy" required
                                value="1" />
                            <span>
                            Ich habe die <a href='/datenschutz'>Datenschutzerklärung</a> gelesen und akzeptiere diese.
                            </span>
                        </label>
                    </div>
                </div>
            </div>

            <div className="form-group">
                <div>
                    <div className="checkbox">
                        <label className="required" htmlFor="consent_update_form_terms_accepted"> 
                        <input type="checkbox"
                            id="consent_update_form_terms_accepted" 
                            name="terms"
                            checked={formData.terms} 
                            onChange={handleChange}
                            required value="1" />
                            <span>
                            Ich habe die <a href='/bedingungen'>Allgemeinen Geschäftsbedingungen</a> gelesen und akzeptiere diese.
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            {message_block}
            <div className="form-group">
                <input type="submit" value="Weiter" className="btn btn-primary pull-right" />
            </div>
        </form>
    </>
}