// @ts-check
import React, { useEffect, useState } from "react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { AdminRoot } from "./AdminRoot.js";
import { telaniNextDataLoader, TelaniNextDownload } from "./TelaniNextDownload.js";
import { ListReferences, listReferencesLoader } from "./ListReferences.js";
import { UserList } from "./UserList.js";
import { TermsConsent } from "./TermsConsent.js";
import { UserDetails, userDetailsAction, userDetailsLoader } from "./UserDetails.js";
import { ChangePassword, changePasswordLoader, changePasswordAction } from "./ChangePassword.js";
import { SubscriptionFirstStep, subscriptionFirstStepLoader } from "./SubscriptionFirstStep.js";
import { ConfirmMail } from "./ConfirmMail.js";
import { Login } from "./Login.js";
import { accountLoader, AccountRoot } from "./AccountRoot.js";
import { emailDebugLoader, EmailDebugOutput } from "./EmailDebugOutput.js";
import { ForgotPassword, forgotPasswordLoader } from "./ForgotPassword.js";
import { SetNewPassword, SetNewPasswordLoader } from "./SetNewPassword.js";
import { LoginRoot } from "./LoginRoot.js";
import { Reference, referenceDataLoader } from "./Reference.js";
import { ErrorPage } from "./ErrorPage.js";
import { RegisterSuccess } from "./RegisterSuccess.js";
import { Register, registerAction, registerLoader } from "./Register.js";
import { IndexPage } from "./IndexPage.js";
import { AdvancedUserDetails, advancedUserDetailsLoader } from "./AdvancedUserDetails.js";
import * as Sentry from "@sentry/react";
import { AdminAddUser, adminAddUserLoader, adminRegisterAction } from "./AdminAddUser.js";

export function App(){

    const [menuRenderPasses, setMenuRenderPasses] = useState(0);

    const onLoginStatusChanged = () => {
        setMenuRenderPasses(menuRenderPasses + 1);
    }

    const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
        createBrowserRouter,
      );

    const adminRouter = sentryCreateBrowserRouter([
        {
            path: "/",
            element: <IndexPage menuRenderPasses={menuRenderPasses} />,
            errorElement: <ErrorPage />,
            children:
            [
                {
                    path: "/",
                    element: <Navigate to="/account" />
                },
                {
                path: "admin",
                element: <AdminRoot />,
                errorElement: <ErrorPage />,
                children:
                [
                    {
                        path: "install-next",
                        loader: telaniNextDataLoader,
                        element: <TelaniNextDownload />
                    },
                    {
                        path: "references",
                        loader: listReferencesLoader,
                        element: <ListReferences />
                    },
                    {
                        path: "reference/new",
                        loader: referenceDataLoader,
                        element: <Reference />
                    },
                    {
                        path: "reference/:referenceId",
                        loader: referenceDataLoader,
                        element: <Reference />
                    },
                    {
                        path: "users",
                        children:[
                            {
                                index: true,
                                element: <UserList />,
                            },
                            {
                                path: "new",
                                loader: adminAddUserLoader,
                                action: adminRegisterAction,
                                element: <AdminAddUser />
                            }
                        ]
                    },
                    {
                        path: "email-debug-output",
                        loader: emailDebugLoader,
                        element: <EmailDebugOutput />
                    },

                ]
            },
            {
                path: "account",
                element: <AccountRoot />,
                loader: accountLoader,
                errorElement: <ErrorPage />,
                children:[
                    {
                        path: "/account/:userId?",
                        loader: userDetailsLoader,
                        action: userDetailsAction,
                        element: <UserDetails />,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: "changePw/:userId?",
                        loader: changePasswordLoader,
                        action: changePasswordAction,
                        element: <ChangePassword />
                    },
                    {
                        path: "manage_subscription/:userId?",
                        loader: subscriptionFirstStepLoader,
                        element: <SubscriptionFirstStep />
                    },
                    {
                        path: "advanced-details/:userId?",
                        loader: advancedUserDetailsLoader,
                        element: <AdvancedUserDetails />
                    },
                ]
            },
            {
                path: "/register",
                element: <Register />,
                loader: registerLoader,
                action: registerAction,
                errorElement: <ErrorPage />,
            },
            {
                path: "/register/success",
                element: <LoginRoot title="Erfolg! Sie können gleich mit telani loslegen"><RegisterSuccess /></LoginRoot>,
                errorElement: <ErrorPage />,
            },
            {
                path: "/confirm-forgotten-password/:code",
                loader: SetNewPasswordLoader,
                element: <LoginRoot title="Passwort vergessen - Neues Passwort">
                    <SetNewPassword onLoginStatusChanged={onLoginStatusChanged} />
                    </LoginRoot>
            },
            {
                path: "/account/terms-consent/:next",
                element: <LoginRoot title="Aktuelle AGB und Datenschutzerklärung">
                    <TermsConsent />
                    </LoginRoot>
            },
            {
                path: "confirm-mail/:code",
                element: <LoginRoot title="E-Mail Adresse bestätigen">
                    <ConfirmMail onLoginStatusChanged={onLoginStatusChanged} />
                    </LoginRoot>
            },
            {
                path: "/login",
                element: <LoginRoot customClass="login" title="Anmelden">
                    <Login onLoginStatusChanged={onLoginStatusChanged} />
                    </LoginRoot>
            },
            {
                path: "/forgot-password",
                loader: forgotPasswordLoader,
                element: <LoginRoot title="Passwort vergessen">
                    <ForgotPassword />
                    </LoginRoot>
            },
        ]}
    ], { future: {
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_relativeSplatPath: true,
        v7_startTransition: true,
        v7_fetcherPersist: true,
        v7_skipActionStatusRevalidation: true,
      }});

    return <RouterProvider router={adminRouter} />
}