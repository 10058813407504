// @ts-check
import React, { useState, useEffect, useRef} from "react";
import { RegisterAdvantages } from "./RegisterAdvantages.js";
import { Form, json, redirect, useActionData, useLoaderData } from "react-router-dom";
import HCaptcha from "@hcaptcha/react-hcaptcha";

export async function registerLoader(){
    const res = await fetch(__API_URL__ + '/webapi/register-token');
    const data = await res.json();
    return json({token: data["token"], siteKey: data["captcha-site-key"]});
}

export async function registerAction({ request, params }){
    if (request.method !== "POST"){
        console.error("Wrong method");
    }
    const formData = await request.formData();
    const newPassword1 = formData.get("user_register[plainPassword][first]");
    const newPassword2 = formData.get("user_register[plainPassword][second]");
    if (newPassword1.length < 8){
        return ({success: false, error: "Ihr Passwort muss mindestens 8 Zeichen lang sein.", target: "password"});
    }
    if (newPassword1 !== newPassword2){
        return ({success: false, error: "Die Passwörter stimmen nicht überein.", target: "password"});
    }
    const res = await fetch(__API_URL__ + "/webapi/register", {method: "POST", body: formData});

    const data = await res.json();
    if (data.success){
        console.log("Successfully registered");
        return redirect("/register/success");
    }
    else {
        console.log("Errors occurred");
        return data;
    }
}

export function Register(){
    let actionData = useActionData();

    let message = null;
    let password_message = null;

    if (actionData !== undefined && actionData !== null && !actionData.success){
        if (actionData.target === "password"){
            password_message = {isError: true, message: actionData.error};
            message = {isError: true, message: actionData.error};
        }
        else{
            message = {isError: true, message: actionData.error};
        }
        console.log("action data", actionData.success, actionData.error)
    }
        
    const {token, siteKey } = useLoaderData();
    const captchaRef = useRef(null);

    const [captchaResponse, setCaptchaResponse] = useState("");

    function handleVerificationSuccess(token, ekey){
        setCaptchaResponse(token);
    }

    const onExpire = () => {
        console.log("hCaptcha Token Expired");
      };
    
    const onError = (err) => {
        console.log(`hCaptcha Error: ${err}`);
      };

      let message_block = message !== null ? <div className={`alert ${message.isError ? "alert-danger" : "alert-success"}`}>{message.message}</div> : null;
      let password_message_block = password_message !== null ? <div className={`alert ${password_message.isError ? "alert-danger" : "alert-success"}`}>{password_message.message}</div> : null;

    return <>
    <div className="wrapper style2">
	    <section id="main" className="container narrowcontainer page-container">
        		<header className="major">
			<h2>Starten Sie Ihren kostenlosen Test von telani</h2>
		</header>
                <section id="content" className="content">
                            <section className="register">
    <RegisterAdvantages />
    <article className="centeredsmall">
        <Form method="post">
        <div>    
            <label htmlFor="user_register_email" className="required">E-Mail Adresse
                <input type="email" id="user_register_email" name="user_register[email]" required={true} autoComplete="email" className="form-control" />
            </label>
    </div>
    <div>
        <label htmlFor="user_register_phone_number" className="required">Telefonnummer
            <input type="text" id="user_register_phone_number" name="user_register[phone_number]" required={true} className="form-control" />
        </label>
    </div>
    <div>
        <label htmlFor="user_register_plainPassword_first" className="required">Passwort (min 8 Zeichen)
            <input type="password" id="user_register_plainPassword_first" name="user_register[plainPassword][first]" required={true} autoComplete="new-password" className="form-control" />
        </label>
    </div>
    <div>    
        <label htmlFor="user_register_plainPassword_second" className="required">Passwort wiederholen
            <input type="password" id="user_register_plainPassword_second" name="user_register[plainPassword][second]" required={true} autoComplete="new-password" className="form-control" />
        </label>
    </div>
    {password_message_block}
    <div>    
        <span className="fake-label">Sind Sie ein Roboter?</span>
        <HCaptcha sitekey={siteKey} 
            ref={captchaRef}
            onError={onError}
            onExpire={onExpire}
            onVerify={(token,ekey) => handleVerificationSuccess(token, ekey)} /> 
    </div>

    <h3>E-Mail Newsletter</h3>
            Wir möchten Ihnen gerne etwa einmal im Monat einen E-Mail Newsletter schicken:
            <div className="form-group"><div>
    <div className="checkbox">
        <label className="required" htmlFor="user_register_email_newsletter"> 
        <input type="checkbox"
            id="user_register_email_newsletter" name="user_register[email_newsletter]"
             value="1"             />

            <span>
            Beim Newsletter anmelden (optional)
            </span>
        </label>
    </div>
</div></div> 
            <p className="small-text">Sie können sich jederzeit abmelden, indem Sie auf den Link in der Fußzeile der E-Mail klicken.
            Informationen zu unseren Datenschutzpraktiken finden Sie <a tabIndex={78}  href="/datenschutz" >hier.</a></p>

            <h3>AGB und Datenschutzerklärung</h3>
                        <div className="form-group"><div>
    <div className="checkbox">
        <label className="required" htmlFor="user_register_privacy_policy_accepted"> 
        <input type="checkbox"
            id="user_register_privacy_policy_accepted" name="user_register[privacy_policy_accepted]" required={true}
             value="1"             />
            <span>
            Ich habe die <a tabIndex={79}  href='/datenschutz'>Datenschutzerklärung</a> gelesen.
            </span>
        </label>
    </div>
</div></div>
    <div className="form-group"><div>
    <div className="checkbox">
        <label className="required" htmlFor="user_register_terms_accepted"> 
        <input type="checkbox"
            id="user_register_terms_accepted" name="user_register[terms_accepted]" required={true}
             value="1"             />
            <span>
            Ich habe die <a tabIndex={80} href='/bedingungen'>Allgemeinen Geschäftsbedingungen</a> gelesen und akzeptiere diese.
            </span>
        </label>
    </div>
</div></div>
            <input type="hidden" id="hCaptchaResponse" name="hCaptchaResponse" value={captchaResponse} />
            <input type="hidden" id="user_register__token" name="user_register[_token]" value={token} />
            <div className="form-group" style={{overflow: "hidden"}}>
            { message_block }
                <input type="submit" value="Registrieren" style={{display: "block", margin: "30px auto 0"}} className="btn btn-primary" />
            </div>
        </Form>
                </article>
            </section>
        </section>
	</section>
</div>
    </>;
}
